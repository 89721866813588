<template>
  <div class="event-subheader" v-if="event">
    <h4
      :class="{
        [`title ${headingClass}`]: true,
        'success-dark--text': assigned || isRegistered
      }"
      :title="assigned && 'You are assigned to this class'"
    >
      <!-- Icon -->
      <i class="fas fa-calendar-check accent--text" v-if="isRegistered" />
      <i
        class="fas fa-credit-card success--text"
        v-else-if="productId && !assigned"
      />
      <span v-else>
        <i :class="assignmentClass" v-if="assigned" />
        <i
          :class="{
            'fa-megaphone': !isOnlineClass,
            'fa-presentation': isOnlineClass,
            'far fa-calendar': event.isRepeatingEvent,
            'grey--text': !isOnlineClass && !productId,
            'success--text': isOnlineClass || productId,
            fas: !event.isRepeatingEvent
          }"
          v-else
        />
      </span>

      <!-- Event/Class Name -->
      {{ event.name }}

      <!-- Edit Link -->
      <router-link class="button outline edit" v-if="canEdit" :to="editRoute">
        <i class="fas fa-edit"></i>
        Edit
      </router-link>
    </h4>

    <template v-if="showDetails">
      <span v-if="event.eventCategory">
        {{ `${event.eventCategory}` }}
      </span>
      <span>&nbsp;|&nbsp;</span>
      <span class="accent--text">{{ formattedEventDate }}</span>
      <span>&nbsp;|&nbsp;</span>
      <span>{{ eventTimeLocal }}</span>
      <span class="translucent">&bull;</span>
      <span class="translucent">{{ eventTime }}</span>
    </template>
  </div>
</template>

<script>
import { PERMISSIONS } from "../models/permissions";
import DateTimeMixin from "./mixins/date-time.mixin";
import RegistrationsMixin from "./mixins/registrations.mixin";

export default {
  name: "EventSubheader",

  mixins: [DateTimeMixin, RegistrationsMixin],

  props: {
    event: Object,
    headingClass: { type: String, default: "h3" },
    showDetails: { type: Boolean, default: true }
  },

  computed: {
    canEdit() {
      return this.permissions.includes(PERMISSIONS.EVENT.EDIT);
    },

    editRoute() {
      const { event, type } = this;
      const params = { [this.idKey]: event.id };
      return { name: `Edit${type}`, params };
    }
  }
};
</script>

<style lang="scss">
.event-subheader {
  @include h6;
  padding-top: 0;
  width: $parent;

  h4 {
    margin: 0;
  }

  .button.edit {
    align-items: center;
    place-content: center;
    display: inline-flex;
    border: 0;
    box-shadow: none;
    color: $link;
    line-height: $lg;
    height: $lg;
  }

  .translucent {
    color: $translucent;
  }
}
</style>
