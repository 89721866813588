var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.event)?_c('div',{staticClass:"event-subheader"},[_c('h4',{class:( _obj = {}, _obj[("title " + _vm.headingClass)] = true, _obj['success-dark--text'] =  _vm.assigned || _vm.isRegistered, _obj ),attrs:{"title":_vm.assigned && 'You are assigned to this class'}},[(_vm.isRegistered)?_c('i',{staticClass:"fas fa-calendar-check accent--text"}):(_vm.productId && !_vm.assigned)?_c('i',{staticClass:"fas fa-credit-card success--text"}):_c('span',[(_vm.assigned)?_c('i',{class:_vm.assignmentClass}):_c('i',{class:{
          'fa-megaphone': !_vm.isOnlineClass,
          'fa-presentation': _vm.isOnlineClass,
          'far fa-calendar': _vm.event.isRepeatingEvent,
          'grey--text': !_vm.isOnlineClass && !_vm.productId,
          'success--text': _vm.isOnlineClass || _vm.productId,
          fas: !_vm.event.isRepeatingEvent
        }})]),_vm._v(" "+_vm._s(_vm.event.name)+" "),(_vm.canEdit)?_c('router-link',{staticClass:"button outline edit",attrs:{"to":_vm.editRoute}},[_c('i',{staticClass:"fas fa-edit"}),_vm._v(" Edit ")]):_vm._e()],1),(_vm.showDetails)?[(_vm.event.eventCategory)?_c('span',[_vm._v(" "+_vm._s(("" + (_vm.event.eventCategory)))+" ")]):_vm._e(),_c('span',[_vm._v(" | ")]),_c('span',{staticClass:"accent--text"},[_vm._v(_vm._s(_vm.formattedEventDate))]),_c('span',[_vm._v(" | ")]),_c('span',[_vm._v(_vm._s(_vm.eventTimeLocal))]),_c('span',{staticClass:"translucent"},[_vm._v("•")]),_c('span',{staticClass:"translucent"},[_vm._v(_vm._s(_vm.eventTime))])]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }