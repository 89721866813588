<template>
  <loader v-if="loading || !event" />

  <section v-else id="event-complete" class="route-content">
    <event-subheader
      class="column h--centered"
      :event="event"
      :show-details="false"
    />

    <branded-divider />

    <div class="description">
      <h3 class="h4">Thanks for attending this {{ type }}!</h3>

      <p>
        Please let us know if you had any issues, or have any questions in
        general.
      </p>

      <p>
        You can browse
        <router-link :to="{ name: listRoute }">
          more {{ isOnlineClass ? "classes" : "events" }}
        </router-link>
        or return to the
        <router-link :to="{ name: 'Home' }">home page</router-link>
      </p>
    </div>
  </section>
</template>

<script>
import { getEventById } from "../models/events";
import BrandedDivider from "./BrandedDivider.vue";
import EventSubheader from "./EventSubheader.vue";
import Loader from "./Loader.vue";
import PermissionsMixin from "./mixins/permissions.mixin";
import RegistrationsMixin from "./mixins/registrations.mixin";

export default {
  name: "EventComplete",

  components: { Loader, EventSubheader, BrandedDivider },

  mixins: [PermissionsMixin, RegistrationsMixin],

  data: () => ({
    confirmComplete: false,
    event: null,
    loading: false,
    participants: []
  }),

  async mounted() {
    const { eventId, classId } = this;

    if (eventId || classId) {
      this.loading = true;
      this.event = await getEventById(eventId || classId);
      this.loading = false;
    }
  }
};
</script>

.<style lang="scss">
#event-complete {
  .description {
    align-items: center;
    display: flex;
    flex-direction: column;
    place-content: center;
    min-height: 25vh;
  }
}
</style>
